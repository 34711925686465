'use strict'


import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/dropdown';

import 'admin-lte';
import naja from 'naja';
import 'sweetalert';
import { Nette, LiveForm } from 'live-form-validation';

import 'admin-lte/dist/css/adminlte.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './css/screen.css';

const devMode = process.env.NODE_ENV !== "production";
window.Masonry = require('masonry-layout');

/* Naja https://naja.js.org/#/quick-start */
window.naja = naja;

/* SweetAlert https://sweetalert.js.org/guides/ */
window.swal = swal;

/* replacnem defaultny alert za peknejsi sweetAlert */
window.alert = (message, title = 'Chyba', type = 'error') => swal(title, message, type);

window.Nette = Nette;
window.LiveForm = LiveForm;

/**
 *  DOM Content Loaded
 */
document.addEventListener('DOMContentLoaded', () =>
{
    devMode && console.log('DOMContentLoaded');

    init();
});

/**
 * popstate (navigation back)
 */
window.addEventListener('popstate', () => {
    devMode && console.log('POPSTATE');

    init();
});


/**
 *  change
 *    - zavolam ak obsahuje element URL [data-ajax-onchange]
 */
document.addEventListener('change', (el) => {

       handleElement(el?.target,'ajaxOnchange','ajaxOnchangename');

});


/**
 *  NAJA click
 *   - pozastavi vykonavanie ak sa na elemente nachadza data-confirm="otazka"
 */
naja.uiHandler.addEventListener('interaction', (event) =>
{
    const {element} = event.detail;
    const question = element?.dataset?.confirm;
    devMode && console.log('naja uiHandler interaction');
    if (question)
    {
        swal(question,{
          dangerMode:true,
          buttons:['zatvoriť', 'OK'],
          icon:'warning',
        }).then(confirmed => {
            if (confirmed)
            {
                // vyriesit ak ajax odosielanie formularov -> element?.type === 'submit' && element.closest('form').submit();
                element?.href && naja.makeRequest('GET',element.href);
            }
        });

        event.preventDefault();
    }

});




naja.snippetHandler.addEventListener('beforeUpdate', (event) =>
{
    devMode && console.log('beforeUpdate snippet '+event.detail.snippet.id);

    /* epg list - prejdeme imageBoxy, pre ktore je nastaveny setInterval (data-interval-id) */
    let imgRefresherList = event.detail.snippet.querySelectorAll('.isRefreshed');
    if (imgRefresherList?.length)
        imgRefresherList.forEach( el => {
            let intervalId;
            if (intervalId = el?.dataset?.intervalId)
                clearInterval(intervalId);

            console.log('clear interval '+intervalId);
        });

});



/**
 * NAJA after UPDATE
 *
 *  po preklesleni snipetu
 *    - aktivujem masonry
 *    - upratanie tooltip aby neostali vysiet
 */
naja.snippetHandler.addEventListener('afterUpdate', (event) =>
{
    devMode && console.log('afterUpdate snippet '+event.detail.snippet.id);

    window.MasonryGrid = activateMasonry(event.detail.snippet);
    activateImageRefresher(event.detail.snippet);

    /* odstranime bootstrap tooltip elementy, ktore mohli ostat vysiet po preklesleni elementu na ktory boli bindnute  */
    let tooltips = document.querySelectorAll(".tooltip.show");

    if (tooltips?.length)
    {
        tooltips.forEach((el) => {
           el.remove();
        });
    }

});



naja.addEventListener('abort', (event) => {
   const { request, abortController } = event.detail;
    devMode &&  console.log('aborted naja request',request);
});


naja.addEventListener('error', (event) => {
    const { request, error } = event.detail;
    devMode &&  console.log('error naja request',request, error);

    swal('Nepodarilo sa načítať stránku','Skúste obnoviť stránku, alebo nás kontaktujte: '+error.message, 'error',{
        button: false
    });

});


/**
 *  - inicializacia Naja  (nette ajax rozsirenie)
 *  - aktivujem ajax volanie pre elementy ktore obsahuju [data-ajax-onload]
 */
function init()
{
    /**
     *  Naja
     */
    try {
        naja.initialize({
            forceRedirect: true
        });
        naja.registerExtension(new LoadingNajaSpinner());
    }
    catch (e)
    {
        devMode && console.warn("reinitializing Naja not allowed");
    }


    /* po nacitani stranky chceme ajaxovo nacitat handler v data-ajax-onload atribude */
    let onloadList = document.querySelectorAll('[data-ajax-onload]');

    if (onloadList.length)
    {
        onloadList.forEach((el) =>
        {
            handleElement(el);
        });
    }
}



function activateMasonry(element, masonryElementName = '.masonry')
{
    let masonryElement = element.querySelector(masonryElementName);

    if (masonryElement && window.Masonry)
    {
        return new Masonry(masonryElement, {
                            columnWidth: '.grid-sizer',
                            itemSelector: '.masonry-item',
                            horizontalOrder: true
                        });
    }
}

/**
 * casovac prekreslovania obrazkov (EPG screenshotov)
 *
 * @param element snippet element - musi obsahovat .imageBox v ktorom je obrazok s `imageClassname`
 * @param imageClassname
 */
function activateImageRefresher(element, imageClassname = '.imageRefresher')
{
    const parentElementName = '.imageBox';
    const refreshInterval = 10000;
    let imageBoxList = element.querySelectorAll(parentElementName + ':not(.isRefreshed)');

    if (imageBoxList?.length)
    {
        let timerid = setInterval(() =>
        {
            imageBoxList.forEach((imageBox, i) =>
            {
                let imageList = imageBox.querySelectorAll(imageClassname);

                if (imageList?.length)
                {
                    imageBox.classList.add('isRefreshed');
                    imageBox.dataset.intervalId = timerid; // podla data-interval-id budeme v naja.snippetHandler. beforeUpdate robit clearInterval

                    let newImage = imageList[0].cloneNode(true);
                    let imgUrl = new URL(newImage.src);
                    // upravime timestampu v url aby sme nacitaci aktualnejsi obrazok (browser cache)
                    imgUrl.searchParams.set('t', Date.now().toString());
                    newImage.src = imgUrl.toString();
                    newImage?.classList?.remove('fade');

                    imageBox.append(newImage);

                    /* fade in */
                    setTimeout( () => newImage.classList.add('fade'), 1000);

                    /* upraceme stare img */
                    if (imageList?.length > 1)
                        imageList[0].remove();
                }
            });

        }, refreshInterval);
    }//< if imageboxList not is refreshed
}


/**
 * zavola ajax makeRequest GET podla atributu[data-{datasetAttr}] na elemente
 * mozeme odoslat aj konkretne data podla atributu datasetAjaxName - funguje pri selectboxe,
 *
 * @param element
 * @param datasetAttr
 * @param datasetAjaxName
 */
function handleElement(element, datasetAttr = 'ajaxOnload', datasetAjaxName = '')
{
    if (element)
    {
        let ajaxUrl = element?.dataset?.[datasetAttr];
        let ajaxOnchangeName = element?.dataset?.[datasetAjaxName];
        let data = {};

        if (element.tagName === 'SELECT' && ajaxOnchangeName)
        {
            data[ajaxOnchangeName] = element.options[element.selectedIndex].value;
        }

        if (ajaxUrl)
        {
            naja.makeRequest('GET', ajaxUrl, data, {
                unique: false,
                history: false,
                forceRedirect: true,
                handleOnElement: element
            });

            return true;
        }
    }

    return false;
}


/**
 * rozsirenie starajuce sa o spinner/loader pri nacitani Najou
 * - btn spinner po kliknuti na button
 * - card loader pri DOMContentLoaded nacitani
 */
class LoadingNajaSpinner {

    initialize(naja)
    {
        naja.uiHandler.addEventListener('interaction', this.locateLoadingIndicator.bind(this));
        naja.addEventListener('start', this.showLoader.bind(this));
        naja.addEventListener('complete', this.hideLoader.bind(this));
    }

    /**
     * interakcia - klik
     * @param detail
     */
    locateLoadingIndicator({detail}) {

        if (detail.element?.innerHTML || detail.element?.getAttribute('type')=='submit')
        {
            detail.options.clickBtn = detail.element;
        }
    }


    animloader()
    {
        let cardLoaderString = `<div class="animloader"><div>načítavam...</div></div>`;
        let tmpNode = document.createElement('div'); tmpNode.innerHTML = cardLoaderString;
        return tmpNode.firstElementChild;
    }


    showLoader({detail}) {

        let cardElement = null;
        const buttonLoader = `<span class="zzBtnSpin spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                        <span class="zzBtnSpin sr-only">Načítavam...</span>`;

        /* spinner v buttone <button> */
        if (detail?.options?.clickBtn && detail?.options?.clickBtn?.innerHTML)
        {
            detail.options.clickBtn.innerHTML = buttonLoader + detail.options.clickBtn.innerHTML;
        }
        /* v buttone <input> spinner byt nemoze */
        else if (detail?.options?.clickBtn && detail?.options?.clickBtn?.tagName == 'INPUT')
        {
            /* ulozime staru hodnotu buttonu */
            detail.options.clickBtn.dataset.oldvalue = detail.options.clickBtn.value;
            detail.options.clickBtn.value = '...čakajte';
        }
        /* card loader */
        else if (detail.options?.handleOnElement)
        {
            /* volanie moze byt priamo na card elemente (data-ajax-onload) alebo vo vnutri card elementu (data-ajax-onchange)  */
            cardElement = detail.options.handleOnElement.classList?.contains('card') ? detail.options.handleOnElement : detail.options.handleOnElement?.closest('.card');

            if (cardElement)
            {
                let cardBody = cardElement.querySelector('.card-body');

                if (cardBody)
                {
                    cardBody?.classList.remove('collapse');
                    cardBody.append(this.animloader());
                }
            }
        }
    }

    hideLoader({detail}) {

        let cardElement = null;

        /* spinner v buttone */
        if (detail?.options?.clickBtn)
        {
            if (detail.options.clickBtn?.tagName == 'INPUT')
            {
                /* obnovime povodnu hodnotu tlacidla */
                detail.options.clickBtn.value = detail.options.clickBtn.dataset.oldvalue;
            }
            else {
                let spinner = detail.options.clickBtn.querySelectorAll(".zzBtnSpin");

                if (spinner.length)
                    spinner.forEach((el) =>
                    {
                        el.remove();
                    });
            }
        }
        /* card loader */
        else if (detail?.options?.handleOnElement)
        {
            /* volanie moze byt priamo na card elemente (data-ajax-onload) alebo vo vnutri card elementu (data-ajax-onchange)  */
            if (detail.options.handleOnElement?.classList?.contains('card'))
                cardElement = detail.options.handleOnElement;
            else if (detail.options.handleOnElement?.closest('.card'))
            {
                cardElement = detail.options.handleOnElement?.closest('.card');
            }
            /* skusim najst podla ID elementu, kedze mozno prist k zmene detail.options.handleOnElement napriklad SELECT > options  */
            else if (detail.options.handleOnElement?.id)
            {
                cardElement = document.getElementById(detail.options.handleOnElement?.id)?.closest('.card');
            }

            if (cardElement)
            {
                try
                {
                    cardElement.classList.remove('loading');
                    cardElement.classList.remove('collapse');

                    /* v elemente card odstranime css loader z tela */
                    let cardBody = cardElement.querySelector('.card-body');
                    let siblings = cardElement.dataset.ajaxSiblings;

                    /* zobrazíme aj karty vsetkych súrodencov (podla data-ajax-siblings attr) */
                    if (siblings)
                    {
                        let siblingList = document.querySelectorAll('[data-ajax-siblings=\'' + siblings + '\']');
                        if (siblingList.length)
                            siblingList.forEach((el) =>
                            {
                                el.classList.remove('collapse');
                                el.classList.remove('loading');
                            });
                    }

                    cardBody.classList.remove('collapse');

                    if (cardBody)
                    {
                        let firstAnimation = cardBody.getElementsByClassName('animloader')[0];
                        firstAnimation.remove();
                    }

                } catch (e)
                {

                }
            }
        }
    }
}